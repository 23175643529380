<template>
  <div class="tabars">
    <div
      class="nav_index_bottomTwo"
      :class="[comObj.navstyle==2?nav_index_bottomthree:'']"
      :style="{'background':comObj.barColor}"
    >
      <!-- 改变背景颜色 -->
      <ul>
        <div class="semicircleBox" v-show="comObj.navstyle==1&&comObj.tarbar.length!=4?true:false">
          <div class="semicircle" :style="{'background':comObj.barColor}"></div>
        </div>
        <li v-for="(item,idx) in comObj.tarbar" :key="idx" @click="change(idx)">
          <!-- 可以控制样式切换 -->
          <div
            class="iconBot"
            :class="[comObj.navstyle==1&&idx==1&&tarbar.length==3||comObj.navstyle==1&&idx==2&&comObj.tarbar.length==5?iconBottwo:'']"
          >
            <img :src="item.selected?item.select:item.defultIcon" alt />
            <!-- 是否为true使用不同的数据 -->
          </div>
          <!-- 改变文字颜色 -->
          <span
            :style="{'color':item.selected?comObj.checkColor:comObj.wordColor,'display':comObj.radio==9?'none':'','marginTop':comObj.radio==6?'0px':'','fontSize':comObj.radio==6?'16px':'12px'}"
          >{{item.navigation}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import stote from "@/store/index";
export default {
  name: "Tarbar",
  props: ["comObj"],
  data() {
    return {
      iconBottwo: "iconBottwo",
      nav_index_bottomthree: "nav_index_bottomthree",
      stote,
    };
  },
  computed: {
  },
  mounted() {
    console.log(this.comObj)
  },
  methods: {
    // 选中状态的修改
    change(idx) {
      for (let i = 0; i < this.comObj.tarbar.length; i++) {
        if (i === idx) {
          this.$router.push(this.comObj.tarbar[i].link)
          this.comObj.tarbar[i].selected = true;
        } else {
          this.comObj.tarbar[i].selected = false;
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.tabars{
  width: 100%;
  position: fixed;
  bottom: -2px;
  z-index: 10;
}
.nav_index_bottomTwo {
  position: relative;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 50px;
  background: #ffffff;
  border-top: 1px solid #e6e6e6;
  line-height: 50px;
  padding: 2px 0;
  ul {
    display: flex;
    width: 100%;
    height: 50px;
    position: relative;
    margin: 0;
    padding: 0;
    .semicircleBox {
      position: absolute;
      top: -27px;
      left: 50%;
      margin-left: -32px;
      height: 25px;
      overflow: hidden;
      .semicircle {
        height: 40px;
        width: 64px;
        background: #ffffff;
        border: {
          radius: 100px 100px 0 0;
          top: 1px solid #e6e6e6;
        }
      }
    }
    li {
      flex: 1;
      width: 100%;
      text-align: center;
      font-size: 12px;
      height: 50px;
      line-height: 50px;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;
      a {
        width: 100%;
        height: 100%;
        color: #333;
      }
      span {
        margin-top: 12px;
      }
      .iconBot {
        top: 0;
        width: 24px;
        position: absolute;
        // overflow: hidden;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .iconBottwo {
        top: -14px;
        width: 36px;
        img {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}
.nav_index_bottomthree {
  border-radius: 25px;
  margin: 0 auto;
  position: absolute;
  top: auto;
  width: 90%;
  right: 0;
  left: 0;
  bottom: 44px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 50px;
  padding: 2px 0;
  // opacity: 0.7;
}
</style>
